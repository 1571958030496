import React from 'react';
import {
  Box,
  Text,
  Center,
  Accordion,
  AccordionItem,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import {useRouter} from 'next/router';

interface FaqProps {
  title?: string;
  faqRef?: React.MutableRefObject<undefined>;
  fractionalOwnershipRef?: React.MutableRefObject<undefined>;
  ownRef?: React.MutableRefObject<undefined>;
  accessRef?: React.MutableRefObject<undefined>;
  buySellRef?: React.MutableRefObject<undefined>;
  giftTransferRef?: React.MutableRefObject<undefined>;
  faqs?: typeof FAQ_CONTENT;
}

export interface IFqaContent {
  title: string;
  content: (string | JSX.Element)[];
  ref?: React.MutableRefObject<undefined>;
}

export function withMailto(
  type: 'hello' | 'info' | 'kyc',
  text?: string,
): JSX.Element {
  const address: {[key: string]: string} = {
    kyc: 'kyc@showpiece.com',
    hello: 'hello@showpiece.com',
    info: 'info@showpiece.com',
  };

  return <a href={`maito:${address[type]}`}>{text ?? address[type]}</a>;
}

export const BANKSY_FAQ_CONTENT: IFqaContent[] = [
  {
    title:
      "What is different about third party assets like Valentine's Day Mascara?",
    content: [
      'These collectables likely have third party majority owners who bear responsibility for the care and display of the asset for the majority of the time.  Showpiece carefully vet these third parties and enter legally binding agreements however, are ultimately not the legal custodian and as such cannot guarantee it’s security.',
    ],
  },
  {
    title:
      "What should I know about artwork Valentine's Day Mascara being left on public display?",
    content: [
      <span>
        A core tenant of the Showpiece platform is democratisation and access to
        incredible assets. None more so than for Banksy VDM which we believe was
        created by the artist for that very purpose. Whilst measures are taken
        to protect the masterpiece by{' '}
        <a
          href="https://www.datocms-assets.com/65318/1690968391-art-conservator-arte-conservation-17-04-23.pdf"
          style={{textDecoration: 'underline', color: '#1ABAB0'}}
        >
          experienced art conservators
        </a>
        , public display does carry a risk that the asset could be damaged.
      </span>,
    ],
  },
  {
    title:
      "If I've purchased Valentine's Day Mascara, can I sell it to someone else?",
    content: [
      'You are able to list your piece(s) in our secondary marketplace which is currently supported in United Kingdom, Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, Canada, Australia, Thailand, Japan, Singapore.',
    ],
  },
];

export const FAQ_CONTENT: IFqaContent[] = [
  {
    title: 'What is fractional ownership?',
    content: [
      'Fractional ownership is the process by which you can own part of an extraordinary item that you otherwise wouldn’t be able to own in its entirety. ',
      'At Showpiece, we split ownership into a fixed number of Pieces and your level of ownership corresponds to how many of these Pieces you hold. E.g., if an item was split into 100,000 pieces and you purchased 10,000 of these pieces, you would own 10% of the underlying item.',
    ],
  },
  {
    title: 'Is this true ownership?',
    content: [
      'Yes.',
      'Using Showpiece, you become a true beneficial owner of the underlying physical item. Following a purchase of your pieces you will receive the documentation that enshrines your legal rights under English Law.',
    ],
  },
  {
    title: 'What are the Terms of Purchase? ',
    content: [
      <span>
        View an example{' '}
        <a href="https://showpiece-assets.s3.eu-west-2.amazonaws.com/terms-edward.pdf">
          Terms of Purchase
        </a>
      </span>,
    ],
  },
  {
    title: 'What benefits will I receive with ownership?',
    content: [
      'At Showpiece you will be able to build a collection of the world’s most extraordinary and culturally significant items. We believe this is the future of collecting and are building a digital experience we hope you’ll love.',
      'Both the physical and digital benefits of ownership will vary from item to item but will always include the economic rights and voting rights over the future of each item. Showpiece and our partners will also provide secure custody and insurance of each item.',
    ],
  },
  {
    title: 'Who can own a Showpiece?',
    content: [
      'Only those over the age of 18 can purchase pieces of a Showpiece. We know this isn’t ideal and apologise for any disappointment caused. A potential solution is for an adult to purchase the fraction(s) in their own name and then once the intended beneficiary reaches the age of 18, they can write to us, and we can transfer the ownership.',
    ],
  },
  {
    title: 'How do I sell my pieces?',
    content: [
      'Once you have pieces in your collection, you can list them for sale on the marketplace to other users at a price of your choice.',
    ],
  },
  {
    title: 'Can I gift ownership of a Showpiece?',
    content: [
      'We have built a dedicated gifting experience which enables you to generate a gift code that can be shared with the intended recipient.  Upon redemption of the code, the recipient will have full beneficial ownership and all related documentation.',
    ],
  },
  {
    title: 'Is this safe?',
    content: [
      'The safety and security of the offering is our number one priority. There are several elements that ensure trust in our offering:',
      'Security of the Showpiece: Showpieces are securely stored by either Showpiece or one of our expert partners. For example, the One Cent Magenta stamp is safely secured in Stanley Gibbon’s vault on the Strand, London.',
      'Legal Security: By using a trust structure, the beneficial rights of those purchasing pieces of the stamp are governed by recognised and widely used elements of English Law. We have invested a lot of time into getting this right. At Showpiece we really are offering true ownership of these extraordinary items.',
      'Digital Security: All transactions and ownership data will be recorded and stored using Amazon’s Quantum Ledger Database (QLDB). This is an immutable solution meaning that a log is maintained containing all previous changes to the database and not just the current state is stored. Cryptographic verification is used to guarantee the veracity of this log.',
    ],
  },
  {
    title: 'Will I receive physical proof of ownership?',
    content: [
      'Following the purchase of a Showpiece you will receive legal documentation declaring your beneficial ownership, as well as a digital certificate indicating the number of pieces you hold. Both will be available for you to print.',
      'In future, we plan to add an ‘Owners Only Shop’ where owners of a particular Showpiece will be able to purchase physical products that are exclusive to its owners. Current plans include items such as a high-quality printed certificate and a framed proof of ownership display that can be placed on your wall.',
    ],
  },
  {
    title: 'Will you accept payment by Cryptocurrency?',
    content: [
      <span>
        We plan to add support for cryptocurrency payments soon. Make sure you
        are signed up to our email communications to find out when this becomes
        available.
      </span>,
    ],
  },
  {
    title: 'Will Collectables on Showpiece ever be sold?',
    content: [
      'Our intention is to hold and protect collectibles indefinitely for the enjoyment of our community of Collectors, rather than to sell the collectibles. If in the future a third party did offer to buy one of the collectibles, the community of Collectors would decide if the item should be sold (see further “What if a buyout offer is made on the underlying item?” below). We therefore cannot guarantee that collectibles will never be sold.',
    ],
  },
  {
    title: 'What if a buyout offer is made on the underlying item?',
    content: [
      'If an offer is made to purchase the whole underlying Showpiece, all owners will receive a vote for each fraction they hold. If over 60% of the pieces voted are in favour of selling, we will sell the underlying item and distribute the net proceeds to owners’ pro-rata according to the number of pieces they hold.',
      'Showpiece will take a de-listing fee in such an event.',
    ],
  },
  {
    title: 'Do I need to provide proof of identity?',
    content: [
      'If you make a purchase in aggregate of over £10,000 GBP (approximately $13,700 USD or €11,800 EUR) you may be required to provide proof of identity due to Know Your Customer (KYC) regulations. If this applies to you, there are two options available:',
      <span>
        1. Prior to your purchase, send us a copy of official government
        documentation such as a passport or driving license as well as
        additional piece of identification showing your address (e.g., utility
        bill or bank statement). Please send the relevant documents to{' '}
        {withMailto('kyc', 'kyc@showpiece.com')}.
      </span>,
      '2. Complete your purchase first, and your requested pieces will be reserved for you until we receive the documentation required. We will request this by email and upon receiving the documents from you the transaction will be approved, and ownership records updated.',
      <span>
        If we, or Stanley Gibbons, already know who you are, we may be able to
        complete the Know Your Customer (KYC) process without requiring you to
        send any documentation. If you have any questions please email us at{' '}
        {withMailto('info', 'info@showpiece.com')}.
      </span>,
    ],
  },
  {
    title: 'How can I contact you?',
    content: [
      <span>
        Please use the live chat in the bottom-right hand corner of your screen
        or email us at {withMailto('hello', 'hello@showpiece.com')}. We would
        love to hear from you.
      </span>,
      'You can also write to us at: Showpiece Technologies Limited, 64-66 Glentham Road, Barnes, London, SW13 9JJ.',
    ],
  },
  {
    title: 'Will Showpiece maintain an interest in any of the items?',
    content: [
      'We want to participate alongside our collectors. Showpiece and/or our partners may maintain an economic interest in any of the items listed on the Showpiece platform.',
    ],
  },
  {
    title: 'How does gifting and transferring ownership work?',
    content: [
      'You can buy pieces as a gift at checkout. Go to buy pieces as normal, and tick the gift checkbox at checkout. Put in the recipients name, and we’ll generate an invite code and invitation card you can give to them as a gift! They simply need to go to showpiece.com  and look for ‘redeem’.',
    ],
  },
  {
    title: 'Gurr Johns content disclaimer',
    content: [
      'By accessing this content you hereby acknowledge and agree that neither Gurr Johns nor its personnel are providing advice or investment advice to Showpiece or its clients or customers or potential clients or customers. You further acknowledge and agree that neither Gurr Johns nor its personnel shall be liable to you for providing any views on an estimate of the value of any Work, nor any views provided as to authenticity or provenance of a Work, and any such views shall not be relied on as an indication of the actual selling price or value of any Work (present or future). You acknowledge and agree that any content provided by Gurr Johns and/or its personnel shall be a matter of opinion and not a statement of fact. You undertake not to place reliance on any such content. You understand and agree that any decisions you make on the basis of any information provided by Gurr Johns and/or its personnel are made solely at your own risk, and Gurr Johns and its personnel shall have no responsibility or liability arising from any such decisions. All content provided by Gurr Johns and/or its personnel are for informational purposes only and do not constitute and should not be construed as a solicitation or offering of any transaction, a recommendation to acquire or dispose of any Work, or the provision of any financial, tax, legal or other advice of any nature whatsoever.',
    ],
  },
  {
    title: 'Citizen and/or Resident of the USA?',
    content: [
      <span>
        Unfortunately, we’re not accepting further purchases from US based
        customers. We want to ensure we always place our collector’s experience
        above all else and believe that at the minute this means taking our US
        site offline for new users and revising our offering. Once this process
        is complete and we relaunch the platform effectively we will be sure to
        provide an update. In the meantime, please accept our apologies for the
        inconvenience.If you are an existing customer based in the USA we will
        continue to honour existing commitments. However, for the time being you
        will be unable to make further purchases on the platform. Please contact
        us at {withMailto('hello', 'hello@showpiece.com')} if you wish to learn
        more about these changes.
      </span>,
    ],
  },
];

export const EDWARD_FAQ_CONTENT: IFqaContent[] = [
  {
    title: 'What is fractional ownership?',
    content: [
      'Fractional ownership is the process by which you can own part of an extraordinary item that you otherwise wouldn’t be able to own in its entirety. ',
      'At Showpiece, we split ownership into a fixed number of Pieces and your level of ownership corresponds to how many of these Pieces you hold. E.g., if an item was split into 100,000 pieces and you purchased 10,000 of these pieces, you would own 10% of the underlying item.',
    ],
  },
  {
    title: 'Is this true ownership?',
    content: [
      'Yes.',
      'Using Showpiece, you become a true beneficial owner of the underlying physical item. Following a purchase of your pieces you will receive the documentation that enshrines your legal rights under English Law.',
    ],
  },
  {
    title: 'What are the Terms of Purchase? ',
    content: [
      <span>
        View the{' '}
        <a href="https://showpiece-assets.s3.eu-west-2.amazonaws.com/terms-edward.pdf">
          Terms of Purchase
        </a>
      </span>,
    ],
  },
  {
    title: 'What benefits will I receive with ownership?',
    content: [
      'At Showpiece you will be able to build a collection of the world’s most extraordinary and culturally significant items. We believe this is the future of collecting and are building a digital experience we hope you’ll love.',
      'Both the physical and digital benefits of ownership will vary from item to item but will always include the economic rights and voting rights over the future of each item. Showpiece and our partners will also provide secure custody and insurance of each item.',
    ],
  },
  {
    title: 'Who can own a Showpiece?',
    content: [
      'Only those over the age of 18 can purchase pieces of a Showpiece. We know this isn’t ideal and apologise for any disappointment caused. A potential solution is for an adult to purchase the fraction(s) in their own name and then once the intended beneficiary reaches the age of 18, they can write to us, and we can transfer the ownership.',
    ],
  },
  {
    title: 'How do I sell my pieces?',
    content: [
      'Once you have pieces in your collection, you can list them for sale on the marketplace to other users at a price of your choice.',
    ],
  },
  {
    title: 'Can I gift ownership of a Showpiece?',
    content: [
      'We have built a dedicated gifting experience which enables you to generate a gift code that can be shared with the intended recipient.  Upon redemption of the code, the recipient will have full beneficial ownership and all related documentation.',
    ],
  },
  {
    title: 'Is this safe?',
    content: [
      'The safety and security of the offering is our number one priority. There are several elements that ensure trust in our offerings:',
      'Security of the Showpiece: The Edward VIII One Penny is safely secured in Baldwin’s vault on the Strand, London. Baldwin’s have also arranged for insurance to cover the coin in case of loss.',
      'Legal Security: By using a trust structure, the beneficial rights of those purchasing pieces of the coin are governed by recognised and widely used elements of English Law. We have invested a lot of time into getting this right. At Showpiece we really are offering true ownership of these extraordinary items.',
      'Digital Security: All transactions and ownership data will be recorded and stored using Amazon’s Quantum Ledger Database (QLDB). This is an immutable solution meaning that a log is maintained containing all previous changes to the database and not just the current state is stored. Cryptographic verification is used to guarantee the veracity of this log.',
    ],
  },
  {
    title: 'Will I receive physical proof of ownership?',
    content: [
      'Following the purchase of a Showpiece you will receive legal documentation declaring your beneficial ownership, as well as a digital certificate indicating the number of pieces you hold. Both will be available for you to print.',
      'In future, we plan to add an ‘Owners Only Shop’ where owners of a particular Showpiece will be able to purchase physical products that are exclusive to its owners. Current plans include items such as a high-quality printed certificate and a framed proof of ownership display that can be placed on your wall.',
    ],
  },
  {
    title: 'Will you accept payment by Cryptocurrency?',
    content: [
      <span>
        We plan to add support for cryptocurrency payments soon. Make sure you
        are signed up to our email communications to find out when this becomes
        available.
      </span>,
    ],
  },
  {
    title: 'Will Collectables on Showpiece ever be sold?',
    content: [
      'Our intention is to hold and protect collectibles indefinitely for the enjoyment of our community of Collectors, rather than to sell the collectibles. If in the future a third party did offer to buy one of the collectibles, the community of Collectors would decide if the item should be sold (see further “What if a buyout offer is made on the underlying item?” below). We therefore cannot guarantee that collectibles will never be sold.',
    ],
  },
  {
    title: 'What if a buyout offer is made on the underlying item?',
    content: [
      'If an offer is made to purchase the whole underlying Showpiece, all owners will receive a vote for each fraction they hold. If over 60% of the pieces voted are in favour of selling, we will sell the underlying item and distribute the net proceeds to owners’ pro-rata according to the number of pieces they hold.',
      'Showpiece will take a de-listing fee in such an event.',
    ],
  },
  {
    title: 'Do I need to provide proof of identity?',
    content: [
      'If you make a purchase in aggregate of over £10,000 GBP (approximately $13,700 USD or €11,800 EUR) you may be required to provide proof of identity due to Know Your Customer (KYC) regulations. If this applies to you, there are two options available:',
      <span>
        1. Prior to your purchase, send us a copy of official government
        documentation such as a passport or driving license as well as
        additional piece of identification showing your address (e.g., utility
        bill or bank statement). Please send the relevant documents to{' '}
        {withMailto('kyc', 'kyc@showpiece.com')}.
      </span>,
      '2. Complete your purchase first, and your requested pieces will be reserved for you until we receive the documentation required. We will request this by email and upon receiving the documents from you the transaction will be approved, and ownership records updated.',
      <span>
        If we, or Stanley Gibbons, already know who you are, we may be able to
        complete the Know Your Customer (KYC) process without requiring you to
        send any documentation. If you have any questions please email us at{' '}
        {withMailto('info', 'info@showpiece.com')}.
      </span>,
    ],
  },
  {
    title: 'How can I contact you?',
    content: [
      <span>
        Please use the live chat in the bottom-right hand corner of your screen
        or email us at {withMailto('hello', 'hello@showpiece.com')}. We would
        love to hear from you.
      </span>,
      'You can also write to us at: Showpiece Technologies Limited, 64-66 Glentham Road, Barnes, London, SW13 9JJ.',
    ],
  },
  {
    title:
      'Are Showpiece intending to maintain an interest in any of the 4,000 pieces?',
    content: [
      'We want to participate alongside our collectors. Showpiece and/or our partners may maintain an economic interest in any of the items listed on the Showpiece platform.',
    ],
  },
  {
    title: 'How does gifting and transferring ownership work?',
    content: [
      'You can buy pieces as a gift at checkout. Go to buy pieces as normal, and tick the gift checkbox at checkout. Put in the recipients name, and we’ll generate an invite code and invitation card you can give to them as a gift! They simply need to go to showpiece.com  and look for ‘redeem’.',
    ],
  },
  {
    title: 'Citizen and/or Resident of the USA?',
    content: [
      <span>
        Unfortunately, we’re not accepting further purchases from US based
        customers. We want to ensure we always place our collector’s experience
        above all else and believe that at the minute this means taking our US
        site offline for new users and revising our offering. Once this process
        is complete and we relaunch the platform effectively we will be sure to
        provide an update. In the meantime, please accept our apologies for the
        inconvenience.If you are an existing customer based in the USA we will
        continue to honour existing commitments. However, for the time being you
        will be unable to make further purchases on the platform. Please contact
        us at {withMailto('hello', 'hello@showpiece.com')} if you wish to learn
        more about these changes.
      </span>,
    ],
  },
];

export const MAGENTA_FAQ_CONTENT: IFqaContent[] = [
  {
    title: 'What is fractional ownership?',
    content: [
      'Fractional ownership is the process by which you can own part of an extraordinary item that you otherwise wouldn’t be able to own in its entirety. ',
      'At Showpiece, we split ownership into a fixed number of Pieces and your level of ownership corresponds to how many of these Pieces you hold. E.g., if an item was split into 100,000 pieces and you purchased 10,000 of these pieces, you would own 10% of the underlying item.',
    ],
  },
  {
    title: 'Is this true ownership?',
    content: [
      'Yes.',
      'Using Showpiece, you become a true beneficial owner of the underlying physical item. Following a purchase of your pieces you will receive the documentation that enshrines your legal rights under English Law.',
    ],
  },
  {
    title: 'What are the Terms of Purchase? ',
    content: [
      <span>
        View the{' '}
        <a href="https://showpiece-assets.s3.eu-west-2.amazonaws.com/terms.pdf">
          Terms of Purchase
        </a>
      </span>,
    ],
  },
  {
    title: 'What benefits will I receive with ownership?',
    content: [
      'At Showpiece you will be able to build a collection of the world’s most extraordinary and culturally significant items. We believe this is the future of collecting and are building a digital experience we hope you’ll love.',
      'Both the physical and digital benefits of ownership will vary from item to item but will always include the economic rights and voting rights over the future of each item. Showpiece and our partners will also provide secure custody and insurance of each item.',
    ],
  },
  {
    title: 'Who can own a Showpiece?',
    content: [
      'Only those over the age of 18 can purchase pieces of a Showpiece. We know this isn’t ideal and apologise for any disappointment caused. A potential solution is for an adult to purchase the fraction(s) in their own name and then once the intended beneficiary reaches the age of 18, they can write to us, and we can transfer the ownership.',
    ],
  },
  {
    title: 'How do I sell my pieces?',
    content: [
      'Once you have pieces in your collection, you can list them for sale on the marketplace to other users at a price of your choice.',
    ],
  },
  {
    title: 'Can I gift ownership of a Showpiece?',
    content: [
      'We have built a dedicated gifting experience which enables you to generate a gift code that can be shared with the intended recipient.  Upon redemption of the code, the recipient will have full beneficial ownership and all related documentation.',
    ],
  },
  {
    title: 'Is this safe?',
    content: [
      'The safety and security of the offering is our number one priority. There are several elements that ensure trust in our offering:',
      'Security of the Showpiece: The One Cent Magenta stamp is safely secured in Stanley Gibbon’s vault on the Strand, London. Stanley Gibbons have also invested significant sums to have a bespoke security display cabinet produced so that the stamp is protected whilst on display. Stanley Gibbons have also arranged for insurance to cover the stamp in case of loss.',
      'Legal Security: By using a trust structure, the beneficial rights of those purchasing pieces of the stamp are governed by recognised and widely used elements of English Law. We have invested a lot of time into getting this right. At Showpiece we really are offering true ownership of these extraordinary items.',
      'Digital Security: All transactions and ownership data will be recorded and stored using Amazon’s Quantum Ledger Database (QLDB). This is an immutable solution meaning that a log is maintained containing all previous changes to the database and not just the current state is stored. Cryptographic verification is used to guarantee the veracity of this log.',
    ],
  },
  {
    title: 'Will I receive physical proof of ownership?',
    content: [
      'Following the purchase of a Showpiece you will receive legal documentation declaring your beneficial ownership, as well as a digital certificate indicating the number of pieces you hold. Both will be available for you to print.',
      'Furthermore, those who purchase over 10 Pieces of the 1c Magenta can request that the documentation be printed and posted to them in hardcopy form',
      'In future, we plan to add an ‘Owners Only Shop’ where owners of a particular Showpiece will be able to purchase physical products that are exclusive to its owners. Current plans include items such as a high-quality printed certificate and a framed proof of ownership display that can be placed on your wall.',
    ],
  },
  {
    title: 'Will you accept payment by Cryptocurrency?',
    content: [
      <span>
        We plan to add support for cryptocurrency payments soon. Make sure you
        are signed up to our email communications to find out when this becomes
        available.
      </span>,
    ],
  },
  {
    title: 'Will Collectables on Showpiece ever be sold?',
    content: [
      'Our intention is to hold and protect collectibles indefinitely for the enjoyment of our community of Collectors, rather than to sell the collectibles. If in the future a third party did offer to buy one of the collectibles, the community of Collectors would decide if the item should be sold (see further “What if a buyout offer is made on the underlying item?” below). We therefore cannot guarantee that collectibles will never be sold.',
    ],
  },
  {
    title: 'What if a buyout offer is made on the underlying item?',
    content: [
      'If an offer is made to purchase the whole underlying Showpiece, all owners will receive a vote for each fraction they hold. If over 60% of the pieces voted are in favour of selling, we will sell the underlying item and distribute the net proceeds to owners’ pro-rata according to the number of pieces they hold.',
      'Showpiece will take a de-listing fee in such an event.',
    ],
  },
  {
    title: 'Do I need to provide proof of identity?',
    content: [
      'If you make a purchase in aggregate of over £10,000 GBP (approximately $13,700 USD or €11,800 EUR) you may be required to provide proof of identity due to Know Your Customer (KYC) regulations. If this applies to you, there are two options available:',
      <span>
        1. Prior to your purchase, send us a copy of official government
        documentation such as a passport or driving license as well as
        additional piece of identification showing your address (e.g., utility
        bill or bank statement). Please send the relevant documents to{' '}
        {withMailto('kyc', 'kyc@showpiece.com')}.
      </span>,
      '2. Complete your purchase first, and your requested pieces will be reserved for you until we receive the documentation required. We will request this by email and upon receiving the documents from you the transaction will be approved, and ownership records updated.',
      <span>
        If we, or Stanley Gibbons, already know who you are, we may be able to
        complete the Know Your Customer (KYC) process without requiring you to
        send any documentation. If you have any questions please email us at{' '}
        {withMailto('info', 'info@showpiece.com')}.
      </span>,
    ],
  },
  {
    title: 'How can I contact you?',
    content: [
      <span>
        Please use the live chat in the bottom-right hand corner of your screen
        or email us at {withMailto('hello', 'hello@showpiece.com')}. We would
        love to hear from you.
      </span>,
      'You can also write to us at: Showpiece Technologies Limited, 64-66 Glentham Road, Barnes, London, SW13 9JJ.',
    ],
  },
  {
    title: 'Are Stanley Gibbons intending to sell all 80,000 pieces?',
    content: [
      'No. Consistent with the concept of democratisation, Stanley Gibbons want to make this opportunity available to all, however, as the world’s leading stamp dealer they also want to retain a meaningful economic interest in the 1c Magenta for the foreseeable future.',
      'Also in keeping with the concept of democratisation, Stanley Gibbons do not aim to retain a majority stake in the stamp and therefore will make 51% of the pieces in the 1c Magenta available for general sale.',
      'Additionally, Stanley Gibbons will not vote on any buyout offer made for the stamp thus allowing the individual owners to decide, as per the terms of purchase, whether to accept an offer or not.',
    ],
  },
  {
    title: 'How does gifting and transferring ownership work?',
    content: [
      'You can buy pieces as a gift at checkout. Go to buy pieces as normal, and tick the gift checkbox at checkout. Put in the recipients name, and we’ll generate an invite code and invitation card you can give to them as a gift! They simply need to go to showpiece.com  and look for ‘redeem’.',
    ],
  },
  {
    title: 'Citizen and/or Resident of the USA?',
    content: [
      <span>
        Unfortunately, we’re not accepting further purchases from US based
        customers. We want to ensure we always place our collector’s experience
        above all else and believe that at the minute this means taking our US
        site offline for new users and revising our offering. Once this process
        is complete and we relaunch the platform effectively we will be sure to
        provide an update. In the meantime, please accept our apologies for the
        inconvenience.If you are an existing customer based in the USA we will
        continue to honour existing commitments. However, for the time being you
        will be unable to make further purchases on the platform. Please contact
        us at {withMailto('hello', 'hello@showpiece.com')} if you wish to learn
        more about these changes.
      </span>,
    ],
  },
];

export const MARKETPLACE_FAQ_CONTENT: IFqaContent[] = [
  {
    title: 'Where can I find out more information?',
    content: [
      <span>
        The Marketplace Terms and Conditions can be found{' '}
        <a href="https://showpiece-assets.s3.eu-west-2.amazonaws.com/secondaryMarketTerms-edward.pdf">
          <b>here. </b>
        </a>
        Please take your time to read these if you are considering making a
        purchase or sale on the Showpiece Marketplace.
      </span>,
    ],
  },
  {
    title: 'How do I list Pieces for sale on the Marketplace?',
    content: [
      'Once logged in, visit the ‘My Collection’ section of our website.On the card of the Showpiece you would like to sell, select ‘View options’, and then ‘Sell Pieces’. You will then be guided through the steps to sell your pieces. You will need to verify your account with Stripe Connect if you haven’t already done so.',
    ],
  },
  {
    title: 'Can anyone sell on the Marketplace?',
    content: [
      'Before a collector can sell on the Marketplace, they will need to verify their account with Stripe Connect, our payments service provider. They may need to perform KYC (Know Your Customer) identity checks before verifying your account.',
    ],
  },
  {
    title: 'Will I be able to sell my Pieces on the marketplace?',
    content: [
      'There is no guarantee that you will be able to successfully sell your Piece on the Marketplace. ',
    ],
  },
  {
    title:
      'If I buy on the Marketplace, will I receive the same benefits available in the initial offering?',
    content: [
      'The same Terms of Purchase apply where relevant. However, physical benefits available in initial offerings will not typically be offered as you are not purchasing from Showpiece when using the Marketplace.',
    ],
  },
  {
    title: 'Is this an investment product?',
    content: [
      'No. Pieces are not an investment. Showpiece make fractional ownership of rare collectables available for our collectors’ enjoyment.',
    ],
  },
  {
    title: 'Who am I buying from/selling to?',
    content: [
      'Showpiece are not a party to the contract between any Buyer and any Seller. Sellers determine the price at which they wish to list their Pieces.',
      'Buyers that make a successful purchase on the Marketplace will pay the price listed by the seller.',
    ],
  },
  {
    title: 'Are there fees to sell on the Marketplace?',
    content: [
      'There will be small fees charged to the seller following a successful transaction. The fee will be clearly communicated to a seller prior to them confirming their listing on the Marketplace. The fees are charged to cover transaction expenses we encounter which can vary based on circumstances such as payment method',
    ],
  },
  {
    title: 'Are there fees to purchase on the Marketplace?',
    content: [
      'No, there are no charges to make a purchase on the Marketplace.',
    ],
  },
  {
    title: 'Will my personal details be shared?',
    content: [
      'We are not a party to the contract between any Buyer and any Seller. If you are a Buyer, your name and email address will be provided to the Seller upon the sale being completed.',
      'If you are a Seller, your name and email address may be provided to the Buyer if the Buyer requests this information from Showpiece in the event of a dispute.',
    ],
  },
  {
    title: 'Can I speak to the team if I have further questions?',
    content: [
      <span>
        Yes! We would love to hear from you. Please get in touch by emailing{' '}
        {withMailto('hello', 'hello@showpiece.com')} ‘marketplace’ as the
        subject line.
      </span>,
    ],
  },
];

const FAQs: React.FC<FaqProps> = ({
  title = 'Frequently Asked Questions',
  faqs = FAQ_CONTENT,
  faqRef,
  fractionalOwnershipRef,
  ownRef,
  accessRef,
  buySellRef,
  giftTransferRef,
}) => {
  const FAQS_WITH_REFS = faqs.map((faq: any) => {
    if (faq.title.includes('fractional ownership')) {
      return {...faq, ref: fractionalOwnershipRef};
    }
    if (faq.title.includes('true ownership')) {
      return {...faq, ref: ownRef};
    }
    if (faq.title.includes('benefits will I receive')) {
      return {...faq, ref: accessRef};
    }
    if (faq.title.includes('be able to sell my pieces')) {
      return {...faq, ref: buySellRef};
    }
    if (faq.title.includes('gifting and transferring')) {
      return {...faq, ref: giftTransferRef};
    }
    return faq;
  });

  const router = useRouter();

  return (
    <Center flexDir="column" ref={faqRef as any} px={4}>
      <Text textStyle={{base: 'h5', md: 'h4'}} textAlign="center">
        {title}
      </Text>
      <Accordion
        allowToggle
        w="100%"
        maxW="767px"
        mt="56px"
        color="charcoal"
        borderColor="superlight"
      >
        {FAQS_WITH_REFS.map((faq: any) => (
          <AccordionItem
            key={faq.title}
            ref={faq.ref as any}
            onClick={() => {
              mixpanel.track('FAQ item click', {
                page: router.pathname,
                item: faq.title,
              });
            }}
          >
            <h2>
              <AccordionButton
                py="27px"
                _focus={{boxShadow: 'none'}}
                _hover={{bgColor: 'white'}}
              >
                <Box
                  flex="1"
                  textAlign="left"
                  textStyle={{base: 'h7', md: 'h6'}}
                  pr="12px"
                >
                  {faq.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={8} textStyle={{base: 'body2', md: 'body1'}}>
              {faq.content.map(
                (value: (string | JSX.Element)[], index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text key={index} mb="20px">
                    {value}
                  </Text>
                ),
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Center>
  );
};

export {FAQs};
